import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { redirectToShopPage, getShopPagePath } from '@root/src/client/utils/vigneron-online';
import { languagesGettersPath, languagesTypes, routerTypes } from '@fwk-client/store/types';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';

interface OrderInput {
  type:CmsEnum<"shop"|"hospitality">,
}

export function useOrder(props:OrderInput, {emit}:any) { 
  const app = getApp();
  const $store = useStore();

  const { type } = props;

  const getUserList = async () => {
    if(type == "shop") {
      var orders = await app.$shop.order.getUserOrders();
      return orders;
    }
    else if(type == "hospitality") {
      var bookings = await app.$hospitality.booking.getUserBookings();
      return bookings;
    }
  }

  const getOrderPath = async (orderID:string) => {
    if(type == "shop") {
      return await getShopPagePath('shop-order', app, app.$shop_order.shop, {
        orderId : orderID
      });
    }
    else if(type == "hospitality") {
      // We redirect to the confirmation page
      var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      return {
        name : 'hospitality-booking',
        params : {
          lang : languageCode,
          bookingId : orderID
        }
      }
    }
    return null;
  }

  const redirectToOrderPage = async (orderID:string) => {
    var orderPath = await getOrderPath(orderID);
    return app.$router.push(orderPath).catch((err:any) => {});
  }

  const retrieveOrder = async (orderID:string, email?:string) => {
    var result = {
      retrieve: false,
      order: null
    }
    if(type == "shop") {
      var response = await app.$shop.order.retrieveOrder(orderID, email);
      if(response.order) {
        result.order = response.order;
      }
    }
    else if(type == "hospitality") {
      var response = await app.$hospitality.booking.retrieveBooking(orderID, email);
      if(response.booking) {
        result.order = response.booking;
      }
    }
    return result;
  }

  const products = computed(() => {
    if(type == 'hospitality') {
        return app.$hospitality_bookingProducts;
    }
    return app.$shop_orderProducts ? app.$shop_orderProducts.filter((product:any) => product.type == 'PRODUCT') : [];
  })

  const activities = computed(() => {
      return app.$shop_orderProducts ? app.$shop_orderProducts.filter((product:any) => product.type == 'ACTIVITY') : [];
  })

  const subTotal = computed(() => {
    return type == "shop" ? app.$shop_orderProductsAmount : app.$hospitality_bookingProductsAmount;
  })
  const deliveryPrice = computed(() => {
    return type == "shop" ? app.$shop_orderDeliveryAmount : undefined;
  })
  const promoCodesReduction = computed(() => {
    return type == "shop" ? app.$shop_orderPromoCodesReduction : undefined;
  })
  const totalPrice = computed(() => {
    return type == "shop" ? app.$shop_orderTotalAmount : app.$hospitality_bookingTotalAmount;
  })

  const promoCodes = computed(() => {
    return type == "shop" ? app.$shop_orderPromoCodes : [];
  })

  const transaction = computed(() => {
    if(type == "shop" && app.$shop_order) {
      return app.$shop_order.transaction;
    }
    else if(type == "hospitality" && app.$hospitality_booking) {
        return app.$hospitality_booking.transaction;
    }
    return null;
  });

  const methodOfPayment = computed(() => {
    if(transaction.value) {
      return transaction.value.method
    }
    return null;
  })

  return {
    getUserList,
    getOrderPath,
    redirectToOrderPage,
    retrieveOrder,
    subTotal,
    deliveryPrice,
    promoCodesReduction,
    totalPrice,
    promoCodes,
    transaction,
    methodOfPayment,
    products,
    activities
  }
  
}