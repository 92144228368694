<template>
  <div class="order content">
    <Retrieve></Retrieve>
  </div>
</template>

<style lang="scss">
  
</style>


<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Retrieve from '../../cms/canvas/shop/Retrieve.vue';

@Component({
  components: { 
    Retrieve
  },
  computed: {
  }
})
export default class Order extends mixins(GenericPage) {

}
</script>