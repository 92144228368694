<template>
  <ul v-if="showTimeline" class="process-steps timeline row">
    <li v-for="(step, index) in timeline" :key="index + '-' + currentLanguageCode" :class="getStepCssClass(step)">
      <span class="i-circled i-bordered mx-auto">{{index+1}}</span>
      <h5>{{ getLocalizedText(getStepLabel(step)) }}</h5>
    </li>
  </ul>
</template>

<style lang="scss">

  .process-steps.timeline {
    justify-content: center;
  }

  .process-steps.timeline li.ui-tabs-done span {
    border-color: #7ac142!important;
    background-color:#7ac142!important;
    color: #FFF;
  }

  .process-steps.timeline li.ui-tabs-done h5 {
    color: #7ac142;
    font-weight: 600;
  }

  .process-steps.timeline li.ui-tabs-cancelled span {
    border-color: #740D0A!important;
    background-color:#740D0A!important;
    color: #FFF;
  }

  .process-steps.timeline li.ui-tabs-cancelled h5 {
    color: #740D0A;
    font-weight: 600;
  }

  .process-steps.timeline li.ui-tabs-active span {
    /*
    border-color: var(--secondary-color) !important;
    background-color: var(--secondary-color) !important;
    */
    border-color: orange !important;
    background-color: orange !important;
    color: #FFF;
  }

  .process-steps.timeline li.ui-tabs-active h5 {
    color: orange;
    font-weight: 600;
  }

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs, watch } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';


/** @cmsOptions */
export interface TimelineOptions {
    
}

/** @cmsLabels */
export interface TimelineLabels {

}

export const shopTimelineDefaultLabels:{[key:string]:any} = {
  "payment.active": {
    fr : "Paiement à valider",
    de : "Zahlung zu bestätigen",
    en : "Payment to validate"
  },
  "review.todo": {
    fr : "Commande à valider",
    de : "",
    en : "Order to validate"
  },
  "review.active": {
    fr : "Validation en cours",
    de : "Validierung läuft",
    en : "Under validation"
  },
  "review.done": {
    fr : "Validée",
    de : "Bestätigt",
    en : "Validated"
  },
  "cancelled.active": {
    fr : "Annulée",
    de : "Abgesagt",
    en : "Cancelled"
  },
  "scheduled.todo": {
    fr : "Planifiée",
    de : "Geplant",
    en : "Scheduled"
  },
  "scheduled.active": {
    fr : "Planifiée",
    de : "Geplant",
    en : "Scheduled"
  },
  "scheduled.done": {
    fr : "Planifiée",
    de : "Geplant",
    en : "Scheduled"
  },
  "preparation.todo": {
    fr : "En préparation",
    de : "In Vorbereitung",
    en : "Under preparation"
  },
  "preparation.active": {
    fr : "En préparation",
    de : "In Vorbereitung",
    en : "Under preparation"
  },
  "preparation.done": {
    fr : "Préparée",
    de : "Bereit",
    en : "Prepared"
  },
  "ready.todo": {
    fr : "Prête",
    de : "Bereit",
    en : "Ready"
  },
  "ready.active": {
    fr : "Prête",
    de : "Bereit",
    en : "Ready"
  },
  "ready.done": {
    fr : "Prête",
    de : "Bereit",
    en : "Ready"
  },
  "shipped.todo": {
    fr : "A expédier",
    de : "Senden",
    en : "To ship"
  },
  "shipped.active": {
    fr : "Expédiée",
    de : "Versendet",
    en : "Shipped"
  },
  "shipped.done": {
    fr : "Expédiée",
    de : "Versendet",
    en : "Shipped"
  },
  "delivered.todo": {
    fr : "Délivrée",
    de : "Geliefert",
    en : "Delivered"
  },
  "delivered.active": {
    fr : "Délivrée",
    de : "Geliefert",
    en : "Delivered"
  },
  "delivered.done": {
    fr : "Délivrée",
    de : "Geliefert",
    en : "Delivered"
  }
}

export const hospitalityTimelineDefaultLabels:{[key:string]:any} = {
  "cancelled.active": {
    fr : "Annulée",
    de : "Abgesagt",
    en : "Cancelled"
  },
  "to_validate.active": {
    fr : "Validation en cours",
    de : "Validierung läuft",
    en : "Under validation"
  },
  "to_validate.done": {
    fr : "Validée",
    de : "Bestätigt",
    en : "Validated"
  },
  "payment.todo": {
    fr : "En attente de paiement",
    de : "Warten auf Zahlung",
    en : "Waiting for payment"
  },
  "payment.active": {
    fr : "En attente de paiement",
    de : "Warten auf Zahlung",
    en : "Waiting for payment"
  },
  "payment.done": {
    fr : "Payée",
    de : "Bezahlt",
    en : "Paid"
  },
  "confirmed.todo": {
    fr : "Confirmée",
    de : "Bestätigt",
    en : "Confirmed"
  },
  "confirmed.active": {
    fr : "Confirmée",
    de : "Bestätigt",
    en : "Confirmed"
  },
  "confirmed.done": {
    fr : "Confirmée",
    de : "Bestätigt",
    en : "Confirmed"
  }
}

/** @cmsSlots */
export interface TimelineSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<TimelineOptions>,
      labels: {
        type: Object as PropType<TimelineLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<TimelineSlots>,
      type: {
        type: String as PropType<"shop"|"hospitality">,
        default: "shop",
        required: false
      },
      mode: {
        type: String as PropType<"PRODUCT" | "ACTIVITY">,
        default: "PRODUCT",
        required: false
      } ,
      isConfirmation: {
        type: Boolean as PropType<boolean>,
        default: false,
        required: false
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $store = useStore();

    const { mode, type, isConfirmation } = toRefs(props);

    const computedOptions:TimelineOptions = {
        ...props.options,
    };

    const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

    const timeline = computed(() => {
      return type.value == "shop" ? 
        (mode.value == "PRODUCT" ? app.$shop_order.productsTimeline : app.$shop_order.activitiesTimeline)
      : app.$hospitality_booking.timeline;
    })

    const showTimeline = computed(() => {
      return !isConfirmation.value && timeline.value && timeline.value.length > 0;
    })

    const getStepLabel = (step:any):string => {
      // return app.$t('shop.order.timeline.steps.'+step.key)
      return type.value == "shop" ? shopTimelineDefaultLabels[step.key] : hospitalityTimelineDefaultLabels[step.key];
    }

    const getStepCssClass = (step:any) => {
      /*
      Possible length of timeline:
                - 2 steps if cancelled
                - 3 steps by default
                - 4 steps if pickup or shipping
      */
      var nbItems = timeline.value.length;
      var colClass = "";
      switch(nbItems) {
        case 2: 
          // 12 / 2 = 6
          colClass = " col-sm-6";
          break;
        case 3:
          // 12 / 3 = 4
          colClass = " col-md-4";
          break;
        case 4:
          // 12 / 4 = 3
          colClass = " col-sm-6 col-md-3";
          break;
        case 5:
          // 12 / 5 = 2
          colClass = " col-sm-4 col-md-2";
          break;

      }
      return 'ui-tabs-'+step.state+colClass;
    }

    return {
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        showTimeline,
        getStepCssClass,
        getStepLabel,
        timeline
    }

  }
})
</script>