<template>
    <div class="delivery-recap">
        <h3>{{ $shop.delivery.getDeliveryTypeTitle($shop_order.methodsOfDelivery) }}</h3>
        <div v-for="(methodOfDelivery, index) of $shop_order.methodsOfDelivery" :key="'delivery-'+index">
            <h4 v-if="$shop_order.methodsOfDelivery.length > 1">{{ $shop.delivery.getDeliveryTitle(methodOfDelivery) }}</h4>
            <p class="note" v-if="methodOfDelivery && methodOfDelivery.note">
                {{ getLocalizedText(methodOfDelivery.note) }}
            </p>
            <div v-if="methodOfDelivery.type == 'PICKUP'">
                <h4>{{ $t('shop.deliveries.pickup.address') }}</h4>                        
                <Address :address="methodOfDelivery.address"></Address>
            </div>
        </div>
        
        <div v-if="$shop.delivery.getOrderDeliveryType($shop_order) == 'SHIPPING' && $shop_order.deliveryDetails">
            <h4>{{ $t('shop.deliveries.shipping.details.title') }}</h4>
            <p class="note">{{ $t('shop.deliveries.shipping.details.note') }}</p>
            <div class="table-responsive details">
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="header">{{ $t('shop.deliveries.shipping.details.date') }}</td>
                            <td class="w-100">{{ deliveryDate }}</td>
                        </tr>
                        <tr v-if="$shop_order.deliveryDetails.provider">
                            <td class="header">{{ $t('shop.deliveries.shipping.details.provider') }}</td>
                            <td class="w-100">{{ $shop_order.deliveryDetails.provider }}</td>
                        </tr>
                        <tr v-if="$shop_order.deliveryDetails.trackingNumbers">
                            <td class="header">{{ $t('shop.deliveries.shipping.details.trackingNumber') }}</td>
                            <td class="w-100">{{ $shop_order.deliveryDetails.trackingNumbers.join(", ") }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="shippingStatus" class="shipping-status">
                <div v-for="(status, index) in shippingStatus" :key="'shippingStatus_'+index" class="package">
                    <h5>{{status.trackingNumber}}</h5>
                    <div v-if="status.status == 'NA'">
                        {{ $t('shop.deliveries.shipping.status-not-available') }}
                    </div>
                    <div v-else class="table-responsive details">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="header">{{ formatHistoryDate(status.history[0].date) }}</td>
                                    <td class="w-100">{{ status.history[0].description }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="shippingHistoryCollapse" v-if="status.history.length > 1">
                            <a href="javascript:void(0)" @click="onDeliveryHistoryClick(index)" :class="{'opened':historyOpened[index]}">
                                    Historique <i class="icon-chevron-down1"></i>
                            </a>
                            <b-collapse v-model="historyOpened[index]" class="history-content">
                                <table class="table">
                                    <tbody>
                                        <tr v-for="(history, historyIndex) in status.history" :key="'shippingStatus_'+index+'_history_'+historyIndex">
                                            <td class="header">{{ formatHistoryDate(history.date) }}</td>
                                            <td class="w-100">{{ history.description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$shop.delivery.getOrderDeliveryType($shop_order) == 'SHIPPING'">
            <h4>{{ $t('shop.deliveries.shipping.address') }}</h4>
            <Address :address="($shop_order.deliveryDetails && $shop_order.deliveryDetails.deliveryAddress) ? $shop_order.deliveryDetails.deliveryAddress : $shop_order.contact.address"></Address>
        </div>
    </div>
</template>

<style>
.delivery .pickup, .delivery .shipping {
    margin-top:10px;
}

.delivery .pickup .address, .delivery .shipping .address {
    margin-top:5px;
}

.delivery-recap .shipping-status {
    display:flex;
    flex-wrap: wrap;
}

.delivery-recap .shipping-status .package {
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0px 10px;
    margin-bottom: 1rem;
    margin-left:20px;
}

.delivery-recap .shipping-status .package h5 {
    font-family: inherit;
    margin-bottom:5px
}

.shippingHistoryCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}

.shippingHistoryCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import Address from './Address.vue';

import { formatDate, FormatDateType } from '@igotweb-node-api-utils/formatter';

@Component({
    components: {
        Address
    },
    computed: {
        
    },
})
export default class DeliveryRecap extends Vue {

    shippingStatus:any = null;
    historyOpened:boolean[] = [];

    created() {
        this.getShippingStatus();
    }

    get deliveryDate() {
        return formatDate(this.$shop_order.deliveryDetails.date, this.$store.state.languages.currentLanguageCode, {type:FormatDateType.LONG});
    }

    getShippingStatus() {
        if(this.$shop.delivery.getOrderDeliveryType(this.$shop_order) == 'SHIPPING' && this.$shop_order.deliveryDetails) {
            // We check the delivery status
            var input = {
                "email" : this.$shop_order.contact.email.email
            }
        
            var options:api.ApiVueOptions =  {
                app: this
            }
            
            api.postAPI('/api/shop/order/'+this.$shop_order._id+'/delivery/shipping-status', input, options).then((response:any) => {
                if(response.status) {
                    this.shippingStatus = response.status;
                    this.historyOpened = [...this.shippingStatus].map((status) => {
                        return false;
                    })
                }
            });
        }
    }

    formatHistoryDate(date:Date) {
        return formatDate(date, this.$store.state.languages.currentLanguageCode, {type:FormatDateType.LONG});
    }

    onDeliveryHistoryClick(index:number) {
        Vue.set(this.historyOpened,index,!this.historyOpened[index]);
    }
}

</script>