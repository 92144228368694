<template>
  <div class="notes">
    <svg v-if="isConfirmation" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="50" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg> 
    <h2>{{ getLocalizedText(labels.title) }}</h2>
    <div class="reference">
        <div>{{ getLocalizedText(labels.reference) }}<span>{{ reference }}</span></div>
    </div>
    <p v-if="isConfirmation">{{ getLocalizedText(labels.confirmation) }}</p>
    <p v-else>{{ getLocalizedText(labels.retrieve) }}</p>
  </div>
</template>

<style lang="scss">

  $green: #7ac142;
  $white: #fff;

  // Misc
  $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

  .checkmark {
    margin-left:auto;
    margin-right:auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: $white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $green;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  .checkmark__circle {
    stroke-dasharray: 250;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $green;
    fill: none;
    animation: stroke .6s $curve forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s $curve .8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 40px $green;
    }
  }

.notes h2 {
  margin-top:20px;
  text-align: center;
  margin-bottom:0px;
}

.notes .reference {
  text-align: center;
}

.notes .reference > div {
  display:inline-block;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.notes .reference > div > span {
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: bold;
}

.notes p {
  text-align: center;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs, watch } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';


/** @cmsOptions */
export interface HeaderOptions {
    
}

/** @cmsLabels */
export interface HeaderLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  reference?:CmsLabel;
  /** @cmsType CmsContent */
  confirmation?:CmsContent;
  /** @cmsType CmsLabel */
  retrieve?:CmsLabel;
}

export const shopDefaultLabels:HeaderLabels = {
    title : {
        fr : "Votre commande",
        de : "Deine Bestellung",
        en : "Your order"
    },
    reference : {
        fr : "Référence de votre commande: ",
        de : "Referenz Ihrer Bestellung: ",
        en : "Your order reference: "
    },
    confirmation : {
        fr : "Voici le récapitulatif de votre commande. Un email va vous être envoyé avec toutes les informations de suivi.",
        de : "Hier sind alle Details Ihrer Bestellung. Es wird eine E-Mail mit allen Folgeinformationen gesendet.",
        en : "Here are all details of your order. An email will be sent with all follow-up information."
    },
    retrieve : {
        fr : "Voici le récapitulatif de votre commande.",
        de : "Hier sind alle Details Ihrer Bestellung.",
        en : "Here are all details of your order."
    }
}

export const hospitalityDefaultLabels:HeaderLabels = {
    title : {
        fr : "Votre réservation",
        de : "Ihre Reservierung",
        en : "Your booking"
    },
    reference : {
        fr : "Référence de votre réservation: ",
        de : "Referenz Ihrer Reservierung: ",
        en : "Your booking reference: "
    },
    confirmation : {
        fr : "Voici le récapitulatif de votre réservation. Un email va vous être envoyé avec toutes les informations de suivi.",
        de : "Hier sind alle Details Ihrer Reservierung. Es wird eine E-Mail mit allen Folgeinformationen gesendet.",
        en : "Here are all details of your booking. An email will be sent with all follow-up information."
    },
    retrieve : {
        fr : "Voici le récapitulatif de votre réservation.",
        de : "Hier sind alle Details Ihrer Reservierung.",
        en : "Here are all details of your booking."
    }
}

/** @cmsSlots */
export interface HeaderSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<HeaderOptions>,
      labels: {
        type: Object as PropType<HeaderLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<HeaderSlots>,
      type: {
        type: String as PropType<"shop"|"hospitality">,
        default: "shop",
        required: false
      },
      isConfirmation: {
        type: Boolean as PropType<boolean>,
        default: false,
        required: false
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $store = useStore();

    const { type, isConfirmation } = toRefs(props);

    const computedOptions:HeaderOptions = {
        ...props.options,
    };

    const labels:HeaderLabels = {
        ...((type.value == "shop") ? shopDefaultLabels : hospitalityDefaultLabels),
        ...props.labels
    }

    const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

    const reference = computed(() => {
      return type.value == "shop" ? app.$shop_order._id : app.$hospitality_booking._id;
    })

    return {
        labels,
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        reference
    }

  }
})
</script>