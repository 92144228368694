<template>
    <div>
        <h3>{{ $t('shop.checkout.contact.info.title') }}</h3>

        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="header">{{ $t('shop.checkout.contact.info.email') }}</td>
                        <td class="w-100">
                            {{ $shop_order.contact.email.email }}
                        </td>
                    </tr>
                    <tr v-if="$shop_order.contact.companyName">
                        <td class="header">{{ $t('shop.checkout.contact.address.company-name') }}</td>
                        <td class="w-100">
                            {{ $shop_order.contact.companyName }}
                        </td>
                    </tr>
                    <tr>
                        <td class="header">{{ $t('shop.checkout.contact.address.fullname') }}</td>
                        <td class="w-100">
                            {{ $shop_order.contact.firstname + " " + $shop_order.contact.lastname}}
                        </td>
                    </tr>
                    <tr>
                        <td class="header">{{ $t('shop.checkout.contact.address.phone') }}</td>
                        <td class="w-100">
                            {{ $shop_order.contact.phone.formatted}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3>{{ $t('shop.checkout.contact.address.title') }}</h3>

        <Address :address="$shop_order.contact.address"></Address>

    </div>
</template>

<style>

/*
.address .table td.header {
    padding-right:15px;
    font-weight: bold;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
}
*/

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import Address from './Address.vue';

@Component({
    components: {
        Address
    },
    computed: {},
})
export default class ContactRecap extends Vue {
    
}

</script>