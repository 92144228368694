<template>
  <Section :options="computedOptions.sectionOptions" v-if="isOrderLoading" class="order-content">

    <div class="d-flex justify-content-center">
      <div class="spinner-grow m-5" role="status">
        <span class="sr-only" hidden>Loading...</span>
      </div>
    </div>

  </Section>

  <Section :options="computedOptions.sectionOptions" class="order-content" v-else-if="showRetrieveContent">

    <div class="steps">

      <div class="row">
        <div class="col">
          <Header :isConfirmation="isConfirmation" :type="computedOptions.type"></Header>
        </div>
      </div>
    
      <div class="row step" v-if="showProducts">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ productsIndex }}</span>
              <h5>{{ getLocalizedText(labels.productsStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <Timeline :isConfirmation="isConfirmation" :type="computedOptions.type" mode="PRODUCT"></Timeline>
          <Products :products="cartProducts" readonly></Products>
        </div>

      </div>

      <div class="row step" v-if="showActivities">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ activitiesIndex }}</span>
              <h5>{{ getLocalizedText(labels.activitiesStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <Timeline :isConfirmation="isConfirmation" :type="computedOptions.type" mode="ACTIVITY"></Timeline>
          <Activities v-if="computedOptions.type == 'shop' && cartActivities.length > 0" :products="cartActivities" readonly></Activities>
        </div>

      </div>

      <div class="row step" v-if="showRentals">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ rentalsIndex }}</span>
              <h5>{{ getLocalizedText(labels.rentalsStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <Timeline :isConfirmation="isConfirmation" :type="computedOptions.type"></Timeline>
          <Rentals :options="{products: $hospitality_bookingProducts, readonly:true}" ></Rentals>
        </div>

      </div>

      <div class="row step" v-if="showValidation">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ validationIndex }}</span>
              <h5>{{ getLocalizedText(labels.validationStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <Validation :options="{products: $hospitality_bookingProducts}"></Validation>
        </div>

      </div>

      <div class="row step" v-if="showContactRecap">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ contactRecapIndex }}</span>
              <h5>{{ getLocalizedText(labels.contactStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <ContactRecap></ContactRecap>
        </div>

      </div>

      <div class="row step" v-if="showDeliveryRecap">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ deliveryRecapIndex }}</span>
              <h5>{{ getLocalizedText(labels.deliveryStep) }}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <DeliveryRecap></DeliveryRecap>
        </div>

      </div>

      <div class="row step">

        <div>
          <ul class="process-steps">
            <li class="ui-tabs-active">
              <span class="i-circled i-bordered mx-auto ui-tabs-anchor">{{ paymentRecapIndex }}</span>
              <h5>{{ getLocalizedText(labels.paymentStep)}}</h5>
            </li>
          </ul>
        </div>

        <div class="col">
          <PaymentRecap :type="computedOptions.type"></PaymentRecap>
        </div>

      </div>

    </div>

  </Section>

  <RetrieveForm v-else
    :options="{type:computedOptions.type}"
  >
  </RetrieveForm>

</template>

<style lang="scss">
  
</style>


<script lang="ts">
import { types as orderTypes, gettersPath as orderGettersPath } from '@fwk-client/modules/shop/stores/order';
import { types as bookingTypes, gettersPath as bookingGettersPath } from '@fwk-client/modules/hospitality/stores/booking';
import { types as vigneronOnlineTypes, gettersPath as vigneronOnlineGettersPath } from '@root/src/client/store/vigneron-online';
import Header from '../../../panels/shop/order/Header.vue';
import Timeline from '../../../panels/shop/order/Timeline.vue';
import DeliveryRecap from '../../../panels/shop/order/DeliveryRecap.vue';
import ContactRecap from '../../../panels/shop/order/ContactRecap.vue';
import PaymentRecap from '../../../panels/shop/order/PaymentRecap.vue';
import RetrieveForm from '../../../panels/shop/order/RetrieveForm.vue';
import { useOrder } from '@root/src/client/composables/useOrder'


import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';


/** @cmsOptions */
export interface RetrieveOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
  /** @cmsType CmsEnum */
  type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface RetrieveLabels {
  /** @cmsType CmsLabel */
  productsStep?:CmsLabel;
  /** @cmsType CmsLabel */
  activitiesStep?:CmsLabel;
  /** @cmsType CmsLabel */
  rentalsStep?:CmsLabel;
  /** @cmsType CmsLabel */
  validationStep?:CmsLabel;
  /** @cmsType CmsLabel */
  contactStep?:CmsLabel;
  /** @cmsType CmsLabel */
  deliveryStep?:CmsLabel;
  /** @cmsType CmsLabel */
  paymentStep?:CmsLabel;
}

export const shopDefaultLabels:RetrieveLabels = {
  productsStep : {
    fr : "Vos produits",
    de : "TODO",
    en : "Your products"
  },
  activitiesStep : {
    fr : "Vos activités",
    de : "TODO",
    en : "Your activities"
  },
  contactStep : {
    fr : "Vos Informations",
    de : "Ihre Informationen",
    en : "Your Details"
  },
  deliveryStep : {
    fr : "Livraison",
    de : "Lieferung",
    en : "Delivery"
  },
  paymentStep : {
    fr : "Paiement",
    de : "Zahlung",
    en : "Payment"
  }
}

export const hospitalityDefaultLabels:RetrieveLabels = {
  rentalsStep : {
    fr : "Votre Réservation",
    de : "Ihre Reservierung",
    en : "Your Booking"
  },
  validationStep : {
    fr : "Validation",
    de : "Validierung",
    en : "Validation"
  },
  paymentStep : {
      fr : "Paiement",
      de : "Zahlung",
      en : "Payment"
  }
}

/** @cmsSlots */
export interface RetrieveSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<RetrieveOptions>,
      labels: {
        type: Object as PropType<RetrieveLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<RetrieveSlots>,
      isConfirmation: Boolean as PropType<boolean>
  },
  components: {
    Header,
    Timeline,
    Products : () => import(/* webpackChunkName: "panels-checkout-cart-products" */ '@root/src/client/components/panels/shop/container/Products.vue'),
    Activities : () => import(/* webpackChunkName: "panels-checkout-cart-activities" */ '@root/src/client/components/panels/shop/container/Activities.vue'),
    Rentals : () => import(/* webpackChunkName: "panels-checkout-cart-rentals" */ '@root/src/client/components/panels/hospitality/container/Rentals.vue'),
    Validation : () => import(/* webpackChunkName: "panels-checkout-cart-validation" */ '@root/src/client/components/panels/hospitality/container/Validation.vue'),
    DeliveryRecap,
    ContactRecap,
    PaymentRecap,
    RetrieveForm,
    Section
  },
  setup(props, context) {
    const app = getApp();
    const store = useStore();

    const computedOptions:RetrieveOptions = {
      sectionOptions : {
        section:false,
        contentWrap:true,
        container:true
      },
      type : "shop",
      ...props.options,
    };

    const { getOrderPath, redirectToOrderPage, retrieveOrder, products, activities } = useOrder({type:computedOptions.type!}, context);

    const labels:RetrieveLabels = {
        ...((computedOptions.type == "shop") ? shopDefaultLabels : hospitalityDefaultLabels),
        ...props.labels
    }

    const currentLanguageCode =  store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    const isLoggedIn = store.getters['authentication/' + authenticationTypes.getters.IS_LOGGED_IN];

    var orderIDToRetrieve:string = "";
    const isOrderLoading:Ref<boolean> = ref(false);

    const showRetrieveContent = computed(() => {
      if(props.isConfirmation) { return true; }
      if(computedOptions.type == "shop" && app.$shop_order && app.$shop_shop) {
        return true;
      }
      else if(computedOptions.type == "hospitality" && app.$hospitality_booking && app.$hospitality_agency) {
        return true;
      }
      return false;
    })

    const showProducts = computed(() => {
      return computedOptions.type == 'shop' && products.value.length > 0
    })

    const showActivities = computed(() => {
      return computedOptions.type == 'shop' && activities.value.length > 0
    })

    const showRentals = computed(() => {
      return computedOptions.type == 'hospitality'
    })

    const showValidation = computed(() => {
      return computedOptions.type == "hospitality" && 
        app.$hospitality_booking && 
        app.$hospitality_booking.status == "TO_VALIDATE" &&
        app.$hospitality_bookingProducts && 
        app.$hospitality_bookingProducts.length > 0 &&
        app.$hospitality_bookingProducts[0].isValidationRequired
    })

    const showContactRecap = computed(() => {
      return computedOptions.type == "shop"
    })

    const showDeliveryRecap = computed(() => {
      return computedOptions.type == "shop" && app.$shop_order && app.$shop_order.methodsOfDelivery && app.$shop_order.methodsOfDelivery.length > 0
    })

    const productsIndex = computed(() => {
      return 1;
    })
    
    const activitiesIndex = computed(() => {
      return showProducts.value ? 2 : 1
    })
    
    const rentalsIndex = computed(() => {
      return 1;
    })
    
    const validationIndex = computed(() => {
      return 2;
    })

    const contactRecapIndex = computed(() => {
      let value = 2;
      if(showProducts.value && showActivities.value) {
        value++;
      }
      return value;
    })

    const deliveryRecapIndex = computed(() => {
      return showContactRecap.value ? contactRecapIndex.value + 1 : contactRecapIndex.value;
    })

    const paymentRecapIndex = computed(() => {
      return showDeliveryRecap.value ? deliveryRecapIndex.value + 1 : deliveryRecapIndex.value;
    })

    if(computedOptions.type == "shop") {
      if(props.isConfirmation) {
          // We are on a confirmation page
          app.$shop.payment.updateStoreAfterConfirmation();
      }
      else {
        // We are on order retrive page
        var vigneronOnlineSite = store.getters[vigneronOnlineGettersPath[vigneronOnlineTypes.getters.GET_VIGNERON_ONLINE_SITE]]
        var shopOrder = store.getters[orderGettersPath[orderTypes.getters.GET_ORDER]];

        if(vigneronOnlineSite && shopOrder && app.$route.params.orderId && app.$route.name == "order") {
          // We redirect to shop path if we are on vigneron-online, there is an order retrieved and we are not already on the shop-order route but on the order route.
          redirectToOrderPage(shopOrder._id)
          return;
        }
        else if(!app.$route.params.orderId) {
          // We clean the order from store if not order id in parameter
          store.commit('shop/order/' + orderTypes.mutations.SET_ORDER,null);
        }
        else if(isLoggedIn && !shopOrder) {
          orderIDToRetrieve = app.$route.params.orderId;
        }
      }
    }
    else if(computedOptions.type == "hospitality") {
      var hospitalityBooking = store.getters[bookingGettersPath[bookingTypes.getters.GET_BOOKING]]
      if(props.isConfirmation) {
        // We are on a confirmation page
        app.$hospitality.payment.updateStoreAfterConfirmation();
      }
      else {
        // We are on order retrieve page
        if(!app.$route.params.bookingId) {
          // We clean the order from store if not order id in parameter
          store.commit('hospitality/booking/' + bookingTypes.mutations.SET_BOOKING,null);
        }
        else if(isLoggedIn && !hospitalityBooking) {
          orderIDToRetrieve = app.$route.params.bookingId;
        }
      }
    }

    if(orderIDToRetrieve != "") {
      isOrderLoading.value = true;
      retrieveOrder(orderIDToRetrieve).then((result:any) => {
        isOrderLoading.value = false;
      });
    }

    return {
        labels,
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        isConfirmation:props.isConfirmation,
        showRetrieveContent,
        showContactRecap,
        showDeliveryRecap,
        productsIndex,
        activitiesIndex,
        rentalsIndex,
        validationIndex,
        contactRecapIndex,
        deliveryRecapIndex,
        paymentRecapIndex,
        getOrderPath,
        isOrderLoading,
        showValidation,
        cartProducts:products,
        cartActivities:activities,
        showProducts,
        showActivities,
        showRentals
    }

  }
})
</script>