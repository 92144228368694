<template>    
  <div class="order-retrieve" ref="retrieveForm">
    <img src="@public/img/vigneron-online/order/background-01.png" />
    <div class="container clearfix">
      
        <div class="vertical-middle">

          <div class="heading-block center nobottomborder">
            <h1>{{ getLocalizedText(labels.title) }}</h1>
          </div>
          <validation-observer v-slot="{ invalid }" slim>
            <form class="row landing-wide-form clearfix" @submit="onFormSubmit">
              <span class="subtitle">{{ getLocalizedText(labels.subTitle) }}</span>
              <div class="col-lg-10">
                <div class="row">
                  <validation-provider mode="aggressive" v-slot="{ errors, classes }" slim>
                    <div class="col-6 mb-0">
                      <input type="text" :class="{'form-control form-control-lg':true, 'required':true, ...classes}" v-model="form.referenceID" :placeholder="getLocalizedText(labels.referencePlaceholder)" required>
                      <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                  <validation-provider mode="aggressive" v-slot="{ errors, classes }" slim>
                    <div class="col-6 mb-0">
                      <input type="email" :class="{'form-control form-control-lg':true, 'required':true, ...classes}" v-model="form.email" :placeholder="getLocalizedText(labels.emailPlaceholder)" required>
                      <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="col-lg-2">
                <button :disabled="invalid" class="button button-large retrieve button-3d ladda-button m-0" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.button) }}</button>
              </div>
            </form>
          </validation-observer>

        </div>

    </div>
  </div>

</template>

<style lang="scss">

  /* Specific margin for page and layout */
  .page-layout .order-retrieve {
    margin-top:0px;
  }

  /* We set specific size and position for the order retrieve section */
  .order-retrieve {
    position:relative;
    background-image: linear-gradient(-180deg,rgba(0,0,0,.3),rgba(0,0,0,0));
    min-height: 450px;
    padding-top:50px;
    padding-bottom:50px;
    overflow:hidden;
    height:100%;
    margin-top:50px;
  }

  .order-retrieve > img {
    position:absolute;
    max-width: none;
    min-width: 100%;
    top:0px;
    z-index: -1;
  }

  .order-retrieve .landing-wide-form {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    position:relative;
    padding:15px;

  }

  .order-retrieve .form-control {
	  height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
  }

  .order-retrieve .heading-block h1 {
    font-size:50px;
    font-weight:900;
    color: #ffffff;
    text-shadow: 0 2px 5px rgba(0,0,0,.5)
    /* text-shadow: #FFF 2px 2px, #FFF -2px -2px, #FFF -2px 2px, #FFF 2px -2px; */
  }

  /* We set style for the subtitle on top of the retrive form */
  .order-retrieve .subtitle {
    display:block;
    text-align: center;
    margin-bottom: 5px;
    font-size: 20px
  }

  /* We set full width for the order retrieve button */
  .order-retrieve button.order {
    width: 100%;
  }

  /* We add top margin when button is below the form fields */
  @media (max-width: 991.98px) {
    .order-retrieve button.order {
      margin-top: 10px!important;
    }
  }
  
</style>


<script lang="ts">
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, onMounted, Ref, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { useOrder } from '@root/src/client/composables/useOrder'
import * as Ladda from 'ladda';
import { redirectToShopPage } from '@root/src/client/utils/vigneron-online';

/** @cmsOptions */
export interface RetrieveFormOptions {
  /** @cmsType CmsEnum */
  type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface RetrieveFormLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  subTitle?:CmsLabel;
  /** @cmsType CmsLabel */
  referencePlaceholder?:CmsLabel;
  /** @cmsType CmsLabel */
  emailPlaceholder?:CmsLabel;
  /** @cmsType CmsLabel */
  button?:CmsLabel;
}  

export const shopDefaultLabels:RetrieveFormLabels = {
  title: {
    fr: "Votre commande",
    de: "Deine Bestellung",
    en: "Your order"
  },
  subTitle: {
    fr: "Affichez votre commande et suivez sa progression.",
    de: "Sehen Sie sich Ihre Bestellung an und verfolgen Sie deren Fortschritt.",
    en: "Display your order and follow it."
  },
  referencePlaceholder: {
    fr: "Référence",
    de: "Referenz Ihrer Bestellung",
    en: "Order ID"
  },
  emailPlaceholder: {
    fr: "Votre email",
    de: "Deine E-Mail",
    en: "Your email"
  },
  button: {
    fr: "Afficher",
    de: "Zum Anzeigen",
    en: "Display"
  }
}

export const hospitalityDefaultLabels:RetrieveFormLabels = {
  title: {
    fr: "Votre réservation",
    de: "Ihre Reservierung",
    en: "Your booking"
  },
  subTitle: {
    fr: "Affichez votre réservation et suivez sa progression.",
    de: "Sehen Sie sich Ihre Reservierung an und verfolgen Sie deren Fortschritt.",
    en: "Display your booking and follow it."
  },
  referencePlaceholder: {
    fr: "Référence",
    de: "Referenz Ihrer Reservierung",
    en: "Booking ID"
  },
  emailPlaceholder: {
    fr: "Votre email",
    de: "Deine E-Mail",
    en: "Your email"
  },
  button: {
    fr: "Afficher",
    de: "Zum Anzeigen",
    en: "Display"
  }
}

/** @cmsSlots */
export interface RetrieveFormSlots {
    
}

export default defineComponent({
    props: {
      options: Object as PropType<RetrieveFormOptions>,
      labels: {
        type: Object as PropType<RetrieveFormLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<RetrieveFormSlots>,
    },
    components : {
    },
    setup(props, context) {

      const app = getApp();
      const router = useRouter();
      const store = useStore();
      const cms = useCmsModule();

      const computedOptions:RetrieveFormOptions = {
        type : "shop",
        ...props.options,
      };

      const { retrieveOrder, redirectToOrderPage } = useOrder({type:computedOptions.type!}, context);

      const labels:RetrieveFormLabels = {
        ...((computedOptions.type == "shop") ? shopDefaultLabels : hospitalityDefaultLabels),
        ...props.labels
      }

      const retrieveForm:Ref<HTMLElement|null> = ref(null);

      const form:any = reactive({
        referenceID:"",
        email:""
      })

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        // @ts-ignore   
        var submitButton:HTMLButtonElement|null = retrieveForm.value.querySelector( 'button.retrieve.ladda-button' );
        if(submitButton){
          laddaSubmit = Ladda.create(submitButton!);
        }
      })

      const onFormSubmit = (evt:Event) => {
        evt.preventDefault();

        laddaSubmit!.start();
        retrieveOrder(form.referenceID, form.email).then((result:any) => {
          if(result.order) {
            redirectToOrderPage(result.order._id);
          }
          else {
            laddaSubmit!.stop();
          }
        });
      }

      if(computedOptions.type == "shop") {
        if(app.$route.params.orderId) {
        // We populate the retrieve order form if there is an order id in parameter
        form.referenceID = app.$route.params.orderId;
      }
      }
      else if(computedOptions.type == "hospitality") {
        if(app.$route.params.bookingId) {
          // We populate the retrieve order form if there is an order id in parameter
          form.referenceID = app.$route.params.orderId;
        }
      }

      return {
        form,
        labels,
        retrieveForm,
        onFormSubmit
      }

    }
})      
</script>