<template>
    
    <div ref="payment">
      <h3>{{ $shop.payment.getPaymentTitle(methodOfPayment) }}</h3>

      <div class="row promo-codes" v-if="promoCodes.length > 0">
          <div class="col-md-auto">
              {{ $t("shop.payments.promoCodes.list") }}
          </div>
          <div class="col">
              <div v-for="(promoCode, index) in promoCodes" :key="index + '-' + promoCode.code">
                  <span class="code">{{promoCode.code}}</span>
                  <span class="note" v-if="promoCode.note">{{ getLocalizedText(promoCode.note) }}</span>
              </div>
          </div>
      </div>
      <div class="table-responsive" v-if="methodOfPayment.type == 'CARD'">
        <table class="table">
            <tbody v-if="transaction.details">
                <tr>
                    <td class="header">{{ $t("shop.payments.card.status") }}</td>
                    <td class="w-100">
                        {{ $t("shop.payments.card.validated") }}
                    </td>
                </tr>
                <tr>
                    <td class="header">{{ $t("shop.payments.card.details") }}</td>
                    <td class="w-100">
                        {{ transaction.details.type }} {{ transaction.details.number }}
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td class="header">Status</td>
                    <td class="w-100">
                        {{ $t("shop.payments.card.under-review") }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="row" v-else-if="methodOfPayment.type == 'PAY_LATER'">
        <div class="col">
          {{ getLocalizedText(methodOfPayment.note) }}
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col-lg-6 clearfix">
          <div class="table-responsive">
            <table class="table cart-recap amounts">
              <tbody>
                <tr class="cart_item" v-if="showSubTotal">
                  <td class="cart-product-name">
                    <strong>{{ labels.subTotal }}</strong>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount">{{ formatPriceAmount(subTotal) }}</span>
                  </td>
                </tr>
                <tr class="cart_item" v-if="showDeliveryPrice">
                    <td class="cart-product-name">
                        <strong>{{ labels.deliveryPrice }}</strong>
                    </td>
                    <td class="cart-product-name">
                        <span class="amount">{{ formatPriceAmount(deliveryPrice) }}</span>
                    </td>
                </tr>
                <tr class="cart_item" v-if="promoCodes.length > 0">
                      <td class="cart-product-name">
                          <strong>{{ labels.promoCodesReduction }}</strong>
                      </td>
                      <td class="cart-product-name">
                          <span class="amount">- {{ formatPriceAmount(promoCodesReduction) }} </span>
                      </td>
                  </tr>
                <tr class="cart_item">
                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ labels.totalPrice }}</strong></span>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ formatPriceAmount(totalPrice) }}</strong></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
       
</template>

<style>

  .promo-codes {
     margin-bottom: 20px;
 }

 .promo-codes span.code {
    font-weight: 600;
    color: #28a745
 }

 .promo-codes span.note {
     margin-left:10px;
 }

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs, watch } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import { useOrder } from '@root/src/client/composables/useOrder'


/** @cmsOptions */
export interface PaymentRecapOptions {
    
}

/** @cmsLabels */
export interface PaymentRecapLabels {
  /** @cmsType CmsLabel */
  subTotal?:CmsLabel;
  /** @cmsType CmsLabel */
  deliveryPrice?:CmsLabel;
  /** @cmsType CmsLabel */
  promoCodesReduction?:CmsLabel;
  /** @cmsType CmsLabel */
  totalPrice?:CmsLabel;
}

/** @cmsSlots */
export interface PaymentRecapSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<PaymentRecapOptions>,
      labels: {
        type: Object as PropType<PaymentRecapLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<PaymentRecapSlots>,
      type: {
        type: String as PropType<"shop"|"hospitality">,
        default: "shop",
        required: false
      }
  },
  components: {
      
  },
  setup(props, context) {
    const app = getApp();
    const $store = useStore();

    const { type } = toRefs(props);

    const computedOptions:PaymentRecapOptions = {
        ...props.options,
    };

    const { subTotal, deliveryPrice, promoCodesReduction, totalPrice, promoCodes, transaction, methodOfPayment, products, activities } = useOrder({type:type.value}, context);

    const labels:PaymentRecapLabels = {
        subTotal: props.type == "shop" ? app.$t("shop.checkout.review-cart.sub-total") : app.$t("hospitality.checkout.review-cart.sub-total"),
        deliveryPrice: props.type == "shop" ? app.$t("shop.deliveries.checkout.sub-total") : undefined,
        promoCodesReduction: props.type == "shop" ? app.$t("shop.payments.promoCodes.total") : app.$t("hospitality.payments.promoCodes.total"),
        totalPrice: props.type == "shop" ? app.$t("shop.payments.total") : app.$t("hospitality.payments.total"),
        ...props.labels
    }

    const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];


    onMounted(() => {
      
    })

    const showSubTotal:Ref<boolean> = ref(true);
    const showDeliveryPrice:Ref<boolean> = ref(true);
    if(type.value == "hospitality" || products.value.length == 0) {
      showSubTotal.value = false;
      showDeliveryPrice.value = false;
    }

    

    return {
        promoCodes,
        labels,
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        methodOfPayment,
        showSubTotal,
        subTotal,
        showDeliveryPrice,
        deliveryPrice,
        totalPrice,
        promoCodesReduction,
        transaction
    }

  }
})

</script>